/*------------------------------------------------------------*/
/*	NAVBAR DARK
/*------------------------------------------------------------*/
.bsnav-dark{
	background: #000;


	.navbar-nav .nav-item{
		color: #fff;
	}//nav-item

	
	.navbar-toggler .navbar-toggler-icon{
		&,
		&::before,
		&::after{
			background-color: #fff;
		}
	}//.navbar-toggler





	.nav-item .caret{
		border-top-color: #fff;
	}//caret

	.nav-item.dropdown .navbar-nav .nav-link .caret,
	.nav-item.dropdown.dropdown-right .navbar-nav .nav-link .caret{
		border-left-color: #fff;
		border-right-color: #fff;
	}

	.nav-item.dropdown.dropdown-horizontal > .navbar-nav .nav-link .caret,
	.nav-item.dropdown.dropdown-horizontal > .navbar-nav .navbar-nav .nav-link .caret{
		border-top-color: #fff;
		top: 2px;
		position: relative;
	}
	





	.nav-item.dropdown > .navbar-nav{
		background: #000;


		.navbar-nav{
			background: #000;
		}//navbar-nav

	}//dropdown > navbar-nav

}//bsnav