/*---------------------------------------------------------*/
/*	NAV ITEM
/*---------------------------------------------------------*/
.navbar-nav .nav-item{
	color: #000;

	$active: #64DD17;


	&:hover > .nav-link,
	&:focus > .nav-link,
	&:active > .nav-link,
	&:active:focus > .nav-link,
	&.active > .nav-link{
		color: $active;
	}//hover, active





	/*------------------------------------------------*/
	/*	FILL
	/*------------------------------------------------*/
	&.nav-item-fill > .nav-link{

		&:hover > .nav-link,
		&:focus > .nav-link,
		&:active > .nav-link,
		&:active:focus > .nav-link{
			background: $active;
			color: #fff;
		}// nav-fill

	}// nav-link


	&.nav-item-fill.active > .nav-link{
		background: $active;
		color: #fff;
	}// nav-fill




	/*------------------------------------------------*/
	/*	LINE
	/*------------------------------------------------*/
	&.nav-item-line > .nav-link::after{
		content: '';
		background: $active;
		width: 0;
		height: 2px;
		left: 50%;
		bottom: 0;
		position: absolute;
		transition: .3s;
	}//nav-line


	&.nav-item-line > .nav-link:hover,
	&.nav-item-line > .nav-link:focus,
	&.nav-item-line > .nav-link:active,
	&.nav-item-line > .nav-link:active:focus,
	&.nav-item-line.active > .nav-link{
		color: $active;

		&::after{
			width: 100%;
			left: 0;
		}//::after
	}// nav-line





	/*------------------------------------------------*/
	/*	DOUBLE LINE
	/*------------------------------------------------*/
	&.nav-item-double-line > .nav-link{

		&::before, &::after{
			content: '';
			background: $active;
			width: 0;
			height: 2px;
			left: 50%;
			position: absolute;
			transition: .3s;
		}//::before / after


		&::before{
			top: 0;
		}//::before
		
		&::after{
			bottom: 0;
		}//::after
		
	}//nav-link


	&.nav-item-double-line > .nav-link:hover,
	&.nav-item-double-line > .nav-link:focus,
	&.nav-item-double-line > .nav-link:active,
	&.nav-item-double-line > .nav-link:active:focus,
	&.nav-item-double-line.active > .nav-link{
		color: $active;

		&::before, &::after{
			width: 100%;
			left: 0;
		}//::before / after

	}





	/*------------------------------------------------*/
	/*	OUTLINE
	/*------------------------------------------------*/
	&.nav-item-outline{
		margin-left: 5px;

		> .nav-link{

			&::before, &::after{
				content: '';
				background: $active;
				width: 0;
				height: 2px;
				position: absolute;
				transition: .3s;
			}

			&::before{
				left: 0;
				top: 0;
			}

			&::after{
				right: 0;
				bottom: 0;
			}

		}// nav-fill-text


		> .nav-link:hover,
		> .nav-link:focus,
		> .nav-link:active,
		> .nav-link:active:focus,
		&.active > .nav-link{
			box-shadow: -2px 0 0 $active inset, 2px 0 0 $active inset;

			&::before, &::after{
				width: 100%;
			}
		}

	}//nav-item-outline


}//nav-item