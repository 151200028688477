/*---------------------------------------------------------*/
/*	NAV LINK
/*---------------------------------------------------------*/
.navbar-expand-lg .navbar-nav .nav-link,
.navbar-nav .nav-link{
	color: inherit;
	padding: 20px 15px;
	display: block;
	position: relative;
	transition: .3s;
	cursor: pointer;
}//nav-link